.shop-nav-bar{
    display: block;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 5rem;
}
.shop-nav-pages{
    list-style: none;
    margin: 0%;
    padding: 0%;
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
    grid-auto-rows: 5rem;
    height: 5rem;
}
.shop-nav-links{
    font-size: 1.5rem;
    cursor: pointer;
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;
    line-height: 5;
}
.shop-nav-links:hover{
    background-color: white;
    border-radius: 1rem;
}
.shop-nav-ba a{
    text-decoration: none;
}
.shop-nav-ba .active{
    background-color: white;
    border-radius: 1rem;
}
