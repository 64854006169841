.updatelogs{
    width: 75%;
    height: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background-color: rgba(0, 0, 0, 0.95);
    color: white;
    border: 2px solid white;
    border-radius: 1rem;
    position: absolute;
    padding: 1rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
}
.updatelogs-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    overflow-y: auto;
}
.updatelogs-close{
    display: grid;
    place-items: center;
    font-size: 1.25rem;
    width: 5rem;
    color: white;
    border: 2px solid white;
    border-radius: 0.5rem;
    margin: 0%;
    cursor: pointer;

}
.updatelogs-close:hover{
    background-color: white;
    color: black;
    border: 2px solid black;
}