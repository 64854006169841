.home{
    position: relative;
}
.home-updatelogs{
    width: 10%;
    position: absolute;
    right: 0%;
    margin: 1rem;
    cursor: pointer;
}
.home-updatelogs:hover{
    scale: 105%;
}
.thecountryquiz-logo{
    display: block;
    width: 85%;
    margin: auto;
    animation: scale 0.5s ease-out;
}
.home-buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.home-buttons img{
    width: 5rem;
    animation: scale 0.5s 0.5s forwards;
    scale: 0%;
    cursor: pointer;
}
.home-buttons img:hover{
    transform: scale(110%);
}
@keyframes scale {
    0%{
        scale: 0%;
    }   
    100%{
        scale: 100%;
    }
}
@media screen and (min-width: 400px) {
    .thecountryquiz-logo{
        width: 20rem;
    }
    .home-updatelogs{
        width: 64px;
    }
}
.info-message{
    width: 85%;
    text-align: center;
    font-size: 1.01rem;
    background-color: rgba(0, 0, 0, 0.95);
    color: white;
    border: 2px solid white;
    border-radius: 1rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 5;
}
.info-button{
    width: 5rem;
    margin: auto;
    border: 2px solid white;
    border-radius: 0.5rem;
    padding: 0.5rem;
    cursor: pointer;
}
.info-button:hover{
    background-color: white;
    color: black;
    border: 2px solid black;
}