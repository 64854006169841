.start{
    text-align: center;
    margin: auto;
    margin-top: 2vh;
    width: 80%;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    border: 2px solid white;
    border-radius: 1rem;
}
.start-info{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    font-size: 1.25rem;
}
.start-items{
    width: 75%;
    margin: auto;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    column-gap: 5%;
    overflow-x: auto;
}

.start-item{
    cursor: pointer;
}
.start-item:hover{
    background-color: white;
    color: black;
    border-radius: 1rem;
}
.start-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25%;
    cursor: pointer;
}
.start-buttons img:hover{
    scale: 105%;
}
@media screen and (min-width: 500px) {
    .start{
        width:50%;
    }
}