.errorMessage{
    width: 14rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 1.25rem;
    background-color: rgba(0, 0, 0, 0.95);
    color: white;
    border-radius: 0.5rem;
    top: 10rem;
    z-index: 10;
}
.errorMessageButton{
    width: 6rem;
    border: 2px solid white;
    border-radius: 0.5rem;
    margin: 0%;
    margin-top: 1rem;
    padding: 0.5rem;
    cursor: pointer;
}
.errorMessageButton:hover{
    background-color: white;
    color: black;
    border: 2px solid black;
}