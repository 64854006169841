.score-wrapper{
    text-align: center;
    font-size: 1.5rem;
    width: 100%;
    height: 2rem;
    line-height: 1.5;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    border: 2px solid white;
    border-radius: 1rem;
    align-items: center;
}
.multiplier-score{
    display: none;
}
@media screen and (min-width: 500px){
    .score-wrapper{
        width: 21rem;
        display: flex;
        flex-direction: row;
        text-align: center;
        font-size: 1.5rem;
        align-items: center;
        justify-content: center;
        border: none;
        color: white;
    }  
    .score{
        font-size: 1.5rem;
        width: 200%;
        height: 2rem;
        line-height: 1.5;
        border: 2px solid white;
        border-radius: 1rem;
        overflow: hidden;
    }
    .multiplier-score{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 2rem;
        border: 2px solid white;
        border-radius: 1rem;
        justify-content: center;
    }
}