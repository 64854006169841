.items-shop{
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
}
.tokens-left{
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;
    justify-content: center;
    border: 2px solid black
}
.goback{
    display: flex;
    justify-content: left;
}
.goBackImage:hover{
    scale: 105%;
}
.tokens-top{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.goplay{
    display: flex;
    justify-content: right;
}
.goPlayImage:hover{
    scale: 105%;
}
.items-forsale{
    height: 76.5vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    overflow: auto;
}
.item-forsale{
    width: 11rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 2px solid white;
    border-radius: 1rem;
}
.cost{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px solid white;
    border-radius: 1rem;
}
.cost:hover{
    scale: 105%;
    background-color: white;
    color: black;
    border: 2px solid black
}