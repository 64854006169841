.play{
    text-align: center;
    height: 100vh;
}
.play img{
    width: 12%;
    margin-top: 2%;
}
.play img:hover{
    scale: 105%
}
.play_back{
    float: left;
}
.play_shop{
    float: right;
}
.mode{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}
.mode-flagsandcapitals{
    margin: 0.5%;
    width: 90%;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    border: 2px solid white;
    border-radius: 1rem;
}
.mode-flagsandcapitals:hover{
    scale: 105%;
    background-color: white;
    color: black;
    border: 2px solid black;
}
.mode-generalfacts{
    margin: 0.5%;
    width: 90%;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    border: 2px solid white;
    border-radius: 1rem;
}
.mode-generalfacts:hover{
    scale: 105%;
    background-color: white;
    color: black;
    border: 2px solid black;
}
.mode-thecountryquiz{
    margin: 0.5%;
    width: 90%;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    border: 2px solid white;
    border-radius: 1rem;
}
.mode-thecountryquiz:hover{
    scale: 105%;
    background-color: white;
    color: black;
    border: 2px solid black;
}
.play ul{
    text-align: left;
    list-style-position: inside;
}
.play a{
    text-decoration: none;
}
.play a.hover{
    scale: 105%;
}
@media screen and (min-width: 500px) {
    .play img{
        width: 3em;
    }    
}
@media screen and (min-width: 650px) {
    .mode-flagsandcapitals{
        width: 20rem;
    }
    .mode-generalfacts{
        width: 20rem;
    }
    .mode-thecountryquiz{
        width: 20rem;
    }
}
@media screen and (max-width: 300px) {
    .mode-flagsandcapitals{
        font-size: 0.925rem;
        width: 12rem;
    }
    .mode-generalfacts{
        font-size: 0.925rem;
        width: 12rem;
    }
    .mode-thecountryquiz{
        font-size: 0.925rem;
        width: 12rem;
    }
}