.skip-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    border: 2px solid white;
    border-radius: 1rem;
    cursor: pointer;
}
.skip-wrapper i{
    font-size: 1.25rem;
    font-weight: 1000;
}
.skip-wrapper:hover{
    scale: 105%;
    background-color: white;
    color: black;
    border: 2px solid black;
}
@media screen and (min-width: 500px) {
    .skip-wrapper{
        width: 10rem;
    }
}