.utilitytab{
    width: 100%;
    display: grid;
    grid-template-rows: auto auto;
    background-color: rgba(0, 0, 0, 0.75);
    row-gap: 1%;
    bottom: 0.5%;
}
.livesandtokens{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 0% 1%;
}
.livesandtokens img{
    width: 3rem;
    height: 3rem;
}
.lives{
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
    border-radius: 1rem;
}
.life-anim{
    animation: animatelife-anim 0.5s;
}
@keyframes animatelives {
    0%{
        background-color: red;
    }
    100%{
        background-color: transparent;
    }
}
@keyframes animatelife {
    0%{
        transform: scale(1);
        opacity: 1;
    }
    50%{
        transform: scale(0.75);
        opacity: 0.25;
    }
    100%{
        transform: scale(1);
        opacity: 1;
    }
}
.lives p{
    font-size: 1rem;
    font-style: oblique;
}
.tokens{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
    border-radius: 1rem;
}
.tokens p{
    font-size: 1rem;
    font-style: oblique;
}
.multiplier{
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
    border-radius: 1rem;
}
.items{
    display: flex;
    flex-direction: row;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    column-gap: 2rem;
    color: white;
    padding: 0rem 2rem;
    overflow: auto;
}
.item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 1rem;
}
.item:hover{
    background-color: white;
    color: black;
}
.item p{
    font-size: 1rem;
}
@media screen and (min-width: 500px) {
    .utilitytab{
        display: flex;
        flex-direction: row;
        gap: 0.75rem;
        padding: 0% 0.5%;
    }
    .livesandtokens{
        display: flex;
        flex-direction: column;
        width: 20%;
    }
    .lives{
        width: 100%;
    }
    .multiplier{
        display: none;
    }
    .items{
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
    }
    .item img{
        width: 100%;
    }
    .item p{
        font-size: .85rem;
    }
}
@media screen and (max-width: 400px){
    .multiplier{
        width: 50%;
    }
    .item img{
        width: 2rem;
    }
    .item p{
        font-size: .75rem;
    }
}
@media (min-aspect-ratio: 1.5/2) {
    
}