.timer-wrapper {
    display: flex;
    justify-content: center;
    margin: 0%;
    padding: 0.25rem;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
}
.countdown{
    width: 10rem;
}
.timer {
    font-family: "Montserrat";
    font-weight: 1000;
    display: flex;
    align-items: center;
}
  
.value {
    font-size: 2.4rem
}