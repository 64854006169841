.profile{
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
}
.top{
    width: 100%;
    height: 6rem;
    display: grid;
    grid-template-columns: 8fr 1fr;
    grid-template-rows: 6rem;
    box-sizing: border-box;
}
.user{
    width: 100%;
    height: 6rem;
    padding: 0.5rem; 
    display: grid;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
}
.user-profile{
    width: 100%;
    display: grid;
    grid-template-columns: 70px 147px 56px;
    column-gap: 10px;
    align-items: center;
}
.user-image{
    width: 64px;
    height: 64px;
    border: 2px solid white;
    border-radius: 0.5rem;
    overflow: hidden;
}
.profile-image{
    width: 64px;
    height: 64px;
}
.user-name{
    width: 100%;
    height: 100%;
    font-size: 1.25rem;
    overflow: hidden;
}
.profile-name{
    height: 1.25rem;
    background-color: rgba(0, 0, 0, 0.75);
    text-align: left;
    overflow: hidden;
}
.edit{
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}
.edit-button{
    display: grid;
    place-items: center;
    width: 3rem;
    height: 1.5rem;
    text-align: center;
    border: 2px solid white;
    border-radius: 0.5rem;
    cursor: pointer;
}
.edit-button:hover{
    background-color: white;
    color: black;
    border: 2px solid black;
}
.input-name{
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    margin: 0%;
    padding: 0%;
}   
.input-name input{
    width: 8.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
    margin: 0%;
    padding: 0%;
    border: 2px solid black;
    border-radius: 0.5rem;
}
.save{
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}
.save-button{
    width: 3rem;
    height: 1.5rem;
    text-align: center;
    border: 2px solid white;
    border-radius: 0.5rem;
    cursor: pointer;
}
.save-button:hover{
    background-color: white;
    color: black;
    border: 2px solid black;
}
.image-picker{
    width: 75%;
    height: 27.5rem;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    position: absolute;
    overflow: auto;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.90);
    border: 2px solid white;
    border-radius: 1rem;
    top: 15%;
    z-index: 5;
    animation: show-imagepicker 0.5s ease-in-out;
}
.image-holder{
    width: 64px;
    height: 64px;
    border: 2px solid white;
    border-radius: 0.5rem;
    overflow: hidden;
    cursor: pointer;
}
.image-holder:hover{
    scale: 105%;
}
.image-available{
    width: 64px;
    height: 64px;
}
@keyframes show-imagepicker {
    0%{
        width: 0%;
    }
    100%{
        width: 75%;
    }
}
.logout{
    width: 100%;
    height: 100%;
    margin: 0%;
    display: grid;
    place-items: center;
}
.logout p{
    width: 5rem;
    height: auto;
    text-align: center;
    font-size: 1.25rem;
    border: 2px solid white;
    border-radius: 0.5rem;
    cursor: pointer;
}
.logout p:hover{
    background-color: white;
    color: black;
    border: 2px solid black;
}
.modal{
    background-color: rgba(0, 0, 0, 0.95);
    color: white;
    border-radius: 0.5rem;
}
.modal-message{
    text-align: center;
    font-size: 1.25rem;
}
.modal-buttons{
    display: flex;
    flex-direction: row;
    gap: 20%;
    align-items: center;
    justify-content: center;
}
.modal-buttons p{
    text-align: center;
    width: 5rem;
    border: 2px solid white;
    border-radius: 0.5rem;
    padding: 0.5rem;
    cursor: pointer;
}
.modal-buttons p:hover{
    background-color: white;
    color: black;
    border: 2px solid black;
}
.inventory-record{
    width: 100%;
    height: 75%;
    padding: 1rem;
    scroll-snap-type: y mandatory;
    overflow-y: auto;
}
.inventory{
    width: 80%;
    height: 85%;
    text-align: center;
    margin: auto;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid white;
    border-radius: 0.5rem;
    overflow: hidden;
    scroll-snap-align: center;
}
.inventory-title{
    font-size: 1.25rem;
}
.inventory-tokens{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.inventory-items{
    width: 75%;
    height: 60%;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    border-radius: 2rem;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.inventory-items::-webkit-scrollbar{
    display: none;
}
.record{
    width: 80%;
    height: 80%;
    text-align: center;
    margin: auto;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid white;
    border-radius: 0.5rem;
    scroll-snap-align: center;
}
.record-title{
    font-size: 1.25rem;
}
.records{
    width: 100%;
    height: 90%;
    overflow: auto;
}
.records p{
    font-size: 1rem;
}
@media screen and (max-width: 380px) {
    .user-profile{
        grid-template-columns: 70px 60px 56px;
        column-gap: 2px;
    }
    .input-name input{
        width: 3.5rem;
    }
}