.fl1_item{
    height: 35rem;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    box-sizing: border-box;
    text-align: center;
}
.fl1_question{
    width: 92.5%;
    height: 4rem;
    font-size: 1.5rem;
    background-color: rgba(0, 0, 0, 75);
    border: 2px solid white;
    border-radius: 1rem;
    display: grid;
    place-items: center;
    overflow: hidden;
}
.fl1_question p{
    margin: 0%;
    padding: 0%;
}
.fl1_image{
    width: 92.5%;
    height: 15rem;
    background-color: rgba(0, 0, 0, 75);
    border: 2px solid white;
    border-radius: 1rem;
    display: grid;
    place-items: center;
}
.fl1_img{
    width: 90%;
    height: 13rem;
    margin: 0%;
    padding: 0%;
    max-height: 14rem;
    border: 2px solid white;
}
.fl1_choices{
    width: 95%;;
    background-color: rgba(0, 0, 0, 75);
    height: 16rem;
}
.fl1_answers{
    height: 16rem;
    margin: 0%;
    padding: 0%;
    text-align: center;
    list-style: none;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 0.25rem;    
}
.fl1_answer{
    font-size: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 75);
    border: 2px solid white;
    border-radius: 1rem;
    cursor: pointer;
    overflow: hidden;
}
.fl1_answer:hover{
    scale: 102.5%;
    background-color: white;
    color: black;
    border: 2px solid black;
}
@media screen and (min-width: 420px) {
    .fl1_item{
        height: 28rem;
    }
    .fl1_question{
        width: 92%;
        height: 3rem;
    }
    .fl1_image{
        width: 92%;
        height: 15rem;
    }
    .fl1_img{
        width: 25rem;
        height: 16rem;
    }
    .fl1_choices{
        height: 9rem;
        width: 92.5%;
    }
    .fl1_answers{
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 0.5rem
    }
}
@media screen and (max-width: 380px) {
    .fl1_item{
        height: 28rem;
    }
    .fl1_question{
        height: 3rem;
    }
    .fl1_image{
        height: 12rem;
    }
    .fl1_img{
        height: 10rem;
    }
    .fl1_choices{
        height: 12rem;
    }
    .fl1_answers{
        height: 12rem;
    }
}