.highscores{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 75%;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    overflow: hidden;
}
.highscores-users{
    width: 85%;
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    border: 2px solid white;
    border-top: 0px;
    border-bottom: 0px;
    border-radius: 1rem;
    padding: 1rem; 
    margin: 1rem auto;
    overflow: hidden;
}
.highscores-title{
    font-size: 1.25rem;
    text-align: center;
}
.highscores-list{
    width: 95%;
    height: 90%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 2px solid white;
    border-left: 0px;
    border-right: 0px;
    border-radius: 2rem;
    padding: 1rem;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.highscores-list::-webkit-scrollbar {
    display: none;
}
.highscores-user{
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    padding: 0.25rem 0rem;
}
.highscores-user-rank{
    display: flex;
    align-items: center;
    justify-content: center;
}
.highscores-user-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 1rem;
}
.highscores-user-info-image{
    border: 2px solid white;
    border-radius: 0.5rem;
    overflow: hidden;
}
.highscores-users-score{
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}