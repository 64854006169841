.error-container{
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
    color: white;
}
.error-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.75);
    border: 2px solid white;
    border-radius: 2rem;
    padding: 3rem;
}
.error404{
    font-size: 4rem;
}
.pagenotfound{
    font-size: 2.5rem;
}
.redirectme{
    display: grid;
    place-items: center;
    justify-content: center;
    width: 10rem;
    font-size: 1.25rem;
    background-color: rgba(0, 0, 0, 0.75);
    border: 2px solid white;
    border-radius: 0.5rem;
    cursor: pointer;
}
.redirectme:hover{
    background-color: white;
    color: black;
    border: 2px solid black;
}